@mixin small {
  @media screen and (max-width: 819px) {
    @content;
  }
}

@mixin large {
  @media screen and (min-width: 820px) {
    @content;
  }
}

@mixin IE {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
