@import 'vars';
@import 'viewports';

@import 'typography';
@import 'page';
@import 'general';
@import 'layout';
@import 'overlay';
@import 'nav';
@import 'box';
@import 'sidebar';
@import 'whoami';

.overview {
  overflow: visible;
  align-items: flex-end;
  display: flex;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.about {
  text-align: left;
  opacity: 1;
  transition: opacity 1s ease-out;
}


.mobile-only {
  display: none;
}

//@media screen and (max-device-width: 1000px) {
  //.desktop-only {
    //display: none;
  //}
  //.mobile-only {
    //display: block;
  //}
//}

//.year {
  //margin-top: 10px;
//}

//.disclaimer {
  //padding: 1em 0;
  //font-size: 10px;
//}

//.key:hover {
  //cursor: not-allowed;
//}

//@media screen and (max-width: 450px) {
  //.hide-s {
    //display: none;
  //}
  //.nav-item {
    //text-decoration: underline;
  //}
  //ul li {
    //font-size: 22px;
  //}
  //section p {
    //font-size: 16px;
  //}

  //.box-demo {
    //flex-grow: 1;
    //margin: 10px 3px;
  //}
//}

