.nav {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 7px 0;
  z-index: 1;
  background-color: white;
  border-top: 1px solid rgba(0,0,0,0.05);

  .windows {
    padding: 3px;
    background-color: #f1edec;
  }

  ul.menu {
    display: flex;
  }

  ul li {
    letter-spacing: 0.04em;
    list-style: none;
    margin-right: 20px;
    font-size: 16px;
    flex-shrink: 1;
  }

  li:first-child {
    margin-left: 20px;
  }

}
