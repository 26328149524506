html, body, div, span, nav, ul, li, p, a, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  scroll-behavior: smooth;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

