@mixin custom-medium {
  @media screen and (min-width: 510px) {
    @content;
  }
}

@mixin custom-small {
  @media screen and (max-width: 509px) {
    @content;
  }
}

.whoami {
  .computer {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    image-rendering: auto;
    image-rendering: pixelated;
    image-rendering: crisp-edges;
  }
}

.computer {
  @include custom-medium {
    position: fixed;
    top: 10px;
  }
}

.computer-screen {
  width: 85%;
  font-size: 13pt;
  font-family: courier new, courier, monospace;
  margin-bottom: 50px;

  @include custom-medium {
    z-index: 1;
    position: relative;
    width: 248px;
    padding-right: 10px;
    left: -23px;
    top: 83px;
    height: 215px;
    overflow-y: scroll;
    color: #00ff00;
  }

  @include IE {
    width: 85%;
    height: 100vh;
    color: black;
    overflow: auto;
    left: 0;
    top: 0;
    padding-top: 160px;
    overflow-y: auto;
  }
}

