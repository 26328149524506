.sidebar > article {
  position: fixed;
  left: 0;
  z-index: 0;
  width: 100%;
  background: white;

  @include large {
    transform-origin: left;
    transform: translateX(3em) rotateZ(90Deg);

    .small {
      display: none;
    }
  }

  @include small {
    text-align: right;

    .large {
      display: none;
    }

    h1, .copyright {
      margin-right: 10px;
    }
  }

  h1 {
    font-size: 30pt;

    @include small {
      font-size: 20pt;
    }
  }
}

.copyright {
  color: $gray;
  font-size: 12px;
  padding-bottom: 2px;
}
