.overlay {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.sky {
  //background: radial-gradient(ellipse at bottom, #2f2563 0%, #0e0e14 100%);
  z-index: -2;
}

.star-container {
  z-index: -1;
}

.star {
  z-index: -1;
  position: absolute;
  background: black;
}

.star-small {
  width: 2px;
  height: 2px;
}

.star-medium {
  width: 3px;
  height: 3px;
}

.star-large {
  width: 5px;
  height: 5px;
}

