.visually-hidden {
  position: absolute;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.disabled {
  pointer-events: none;
  color: $gray;
}

label:hover {
  cursor: pointer;
}

render:not(.rendered) {
  display: none;
}
