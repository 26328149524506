.boxes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.box-wrapper {

}

.box {
  margin: 50px 10px 50px 75px;
  width: 75%;
  font-size: 20pt;
  //position: sticky;
  //top: 0;
  display: grid;
  grid-template-columns: 1fr 1fr auto 1fr 1fr 1fr;
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 25px;
  grid-row-gap: 20px;
  grid-template-areas:
    "name name date desc desc desc"
    "code demo .    desc desc desc";

  @include small {
    margin: 50px 10px;
    width: 85%;
    font-size: 16pt;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      "name date"
      "code demo"
      "desc desc";
  }

  .name {
    grid-area: name;
  }

  .date {
    grid-area: date;
    text-align: right;
  }

  .description {
    grid-area: desc;
  }

  .link {
  }

  .link-code {
    grid-area: code;
  }

  .link-demo {
    grid-area: demo;
  }
}
